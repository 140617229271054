$(document).ready(() => {
    $('.l-pc .slick').slick({
        autoplay: true,
        dots: true,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [{
            breakpoint: 768
        }],
        centerMode: true,
        centerPadding: 100
    });

    $('.l-sp .news_contentList.slick').slick({
        // autoplay: true,
        dots: true,
        // infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        // centerMode: true,
        centerPadding: 100
    });

    $('.l-sp .recruit_welfare .slick').slick({
        // autoplay: true,
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: 0
    });
});